<!--
 * @Description: 角色权限
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:03:30
 * @LastEditTime: 2022-07-15 16:01:58
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="roleId">
              <el-input
                v-model="searchForm.roleId"
                placeholder="角色ID"
                title="角色ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleName">
              <el-input
                v-model="searchForm.roleName"
                placeholder="角色名称"
                title="角色名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleStatus">
              <e6-vr-select
                v-model="searchForm.roleStatus"
                :data="statusList"
                placeholder="角色状态"
                title="角色状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleGroup">
              <e6-vr-select
                v-model="searchForm.roleGroup"
                :data="roleTypeList"
                placeholder="角色组"
                title="角色组"
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createBy">
              <el-input
                v-model="searchForm.createBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="添加用户" @click="handleAdd"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>

              <!-- <span v-elseif="column.fieldName === 'chargeCode'">
                <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span> -->
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加角色弹框 -->
    <add-role-dialog
      :addRoleDialog="addRoleDialog"
      @handleClose="handleClose"
      type="add"
      @refresh="queryList"
    ></add-role-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getRoleList,
  roleBatchEnable,
  roleBatchDisEnable,
  findDownList
} from "@/api";
import addRoleDialog from "./addRoleDialog.vue";
export default {
  name: "roleManage",
  data() {
    return {
      loading: false,
      searchForm: {
        roleGroup: [], //角色组
        roleId: "", //角色id
        roleName: "", //角色名称
        roleStatus: "", //角色状态
        createBy: "", //创建人
        startCreatedTime: "", //创建时间开始
        endCreatedTime: "", //创建时间结束
        createTime: [], //创建时间
        pageIndex: 1,
        pageSize: 20
      },
      roleTypeList: [], //角色组下拉框
      statusList: [
        { id: 0, label: "禁用" },
        { id: 1, label: "正常" }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "roleId",
          display: true,
          fieldLabel: "角色ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleName",
          display: true,
          fieldLabel: "角色名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleStatus",
          display: true,
          fieldLabel: "角色状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleGroup",
          display: true,
          fieldLabel: "角色组",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "privilegeSum",
          display: true,
          fieldLabel: "权限",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color"
        }
      ], //表头
      tableData: [], // 表格数据
      selColumnId: [], //选中的数据id
      /**添加角色弹框 */
      addRoleDialog: false
    };
  },
  components: { addRoleDialog },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.loading = true;
        let promiseList = [findDownList(["roleGroup"])];
        let [roleRes] = await Promise.all(promiseList);
        //获取联系人下拉框
        this.roleTypeList = this.getFreezeResponse(roleRes, {
          path: "data.roleGroup"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取角色权限列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getRoleList(this.searchForm);
        this.tableData = res.data.array;
        this.tableData.map(item => {
          item.roleGroup = item.roleGroup.join("，");
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.roleId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //处理启用禁用
    changeStatus(status) {
      if (this.selColumnId.length) {
        let tipTitle =
          status == 1 ? "是否确认启用勾选的角色？" : "是否确认禁用勾选的角色？";
        let title = status == 1 ? "启用角色" : "禁用角色";
        this.$confirm(tipTitle, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (status == 1) {
              this.batchEnable();
            } else {
              this.batchDisEnable();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message.warning("未勾选数据");
      }
    },
    //批量启用请求
    async batchEnable() {
      try {
        let res = await roleBatchEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //批量禁用请求
    async batchDisEnable() {
      try {
        let res = await roleBatchDisEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/roleDetail",
          params: {
            refresh: true,
            roleId: row.roleId
          }
        });
      }
    },
    /*****添加角色 */
    handleAdd() {
      this.addRoleDialog = true;
    },

    //关闭弹框
    handleClose() {
      this.addRoleDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
